// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static get targets() {
    return ["mobileNavHamburger", "mobileNavItems", "mobileNavProfile", "signOutLink", "userButton"];
  }

  initialize() {
    this.signOutLinkVisible = false;
    this.mobileNavVisible = false;
  }

  connect() {}

  disconnect() {}

  toggleSignOutLink() {
    if (this.signOutLinkVisible) {
      this.signOutLinkTarget.classList.add("hidden");
    } else {
      this.signOutLinkTarget.classList.remove("hidden");
    }

    this.signOutLinkVisible = !this.signOutLinkVisible;
  }

  toggleMobileNav() {
    if (this.mobileNavVisible) {
      this.mobileNavItemsTarget.classList.add("hidden");
      this.mobileNavProfileTarget.classList.add("hidden");
    } else {
      this.mobileNavItemsTarget.classList.remove("hidden");
      this.mobileNavProfileTarget.classList.remove("hidden");
    }

    this.mobileNavVisible = !this.mobileNavVisible;
  }
}
