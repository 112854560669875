import { Controller } from "@hotwired/stimulus"

import Sortable from "sortablejs"

export default class extends Controller {

  static get values() {
    return { handle: String }
  }

  connect() {
    this.sortable = Sortable.create(this.element, {
      handle: this.handleValue,
      onEnd: this.end.bind(this)
    })
  }

  async end(event) {
    const updateUrl = event.item.dataset.listableUpdateUrl
    const csrfToken = document.querySelector('meta[name="csrf-token"]').content

    let body = {
      position: event.newIndex + 1
    }

    const headers = new Headers({
      'Content-Type': 'application/json',
      'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
    })

    await fetch(updateUrl, {
      method: 'PATCH',
      mode: 'cors',
      cache: 'no-cache',
      headers: headers,
      redirect: "follow",
      referrerPolicy: "no-referrer",
      body: JSON.stringify(body)
    })
    .then(response => console.log(response))
    .catch((response) => console.error(response))
  }
}
