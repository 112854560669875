import ApexCharts from "apexcharts"
import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="apexchart"
export default class extends Controller {
  static get targets() {
    return ["wrapper"];
  }

  static get values() {
    return { options: String };
  }

  initialize() {
    this.chart = null;
  }

  connect() {
    this.chart = new ApexCharts(this.wrapperTarget, JSON.parse(this.optionsValue));
    this.chart.render();
  }

  disconnect() {
    this.chart = null;
  }
}
