import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="mobile-nav"
export default class extends Controller {
  static get targets() {
    return ["menu"]
  }

  toggleMenu(event) {
    event.preventDefault()
    document.dispatchEvent(new Event("cxe.sidebar.toggle"))
  }
}
