import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="sidebar"
export default class extends Controller {
  connect() {
    this.toggleListener = document.addEventListener(
      "cxe.sidebar.toggle",
      this.toggleMenu.bind(this)
    )
  }

  disconnect() {
    this.element.removeEventListener(
      "cxe.sidebar.toggle",
      this.toggleListener
    )
  }

  toggleMenu() {
    if (this.element.classList.contains("hidden")) {
      this.element.classList.remove("hidden")
    } else {
      this.element.classList.add("hidden")
    }
  }
}
