import { Controller } from "@hotwired/stimulus"

import { LuminousGallery } from "luminous-lightbox"

// Connects to data-controller="luminous-gallery"
export default class extends Controller {
  initialize() {
    this.galleryOpts = {
      arrowNavigation: true
    }

    this.luminousOpts = {
      caption: (el) => `
      <h3 class="text-md font-bold">${el.dataset.title}</h3>
      <p class="text-sm font-light">${el.dataset.description}</p>
      `
    }
  }

  connect() {
  this.luminousGallery = new LuminousGallery(this.element.querySelectorAll(".luminous-gallery-item"), this.galleryOpts, this.luminousOpts)
  }

  disconnect() {
    this.luminousGallery = null
  }
}
