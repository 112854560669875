import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="sidebar-expandable"
export default class extends Controller {
  static get targets () {
    return ["expandableArea", "expandableIcon", "link"]
  }

  connect() {}

  toggle(event) {
    if (event.target != this.linkTarget) {
      event.preventDefault()
      if (this.expandableAreaTarget.classList.contains("hidden")) {
        this.expandableAreaTarget.classList.remove("hidden")
        this.element.classList.add("bg-lightGray-50")
        this.expandableIconTarget.classList.add("rotate-90")
      } else {
        this.expandableAreaTarget.classList.add("hidden")
        this.element.classList.remove("bg-lightGray-50")
        this.expandableIconTarget.classList.remove("rotate-90")
      }
    }
  }
}
