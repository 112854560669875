import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "placeholder" ]
  connect() {
    this.appendListener = this.element.addEventListener(
      "DOMNodeInserted",
      this.onChange.bind(this)
    )
  }

  disconnect() {
    this.element.removeEventListener(
      "DOMNodeInserted",
      this.appendListener
    )
  }

  onChange(event) {
    if (this.hasPlaceholderTarget) {
      this.placeholderTarget.classList.add("hidden")
    }
  }
}
