import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static get values() {
    return { animationTime: 300 }
  }

  dismiss(event) {
    this.element.classList.add("pt-0")
    this.element.classList.remove("pt-8")
    this.element.classList.add("opacity-0", "h-0")
    setTimeout(() => {
      this.element.remove()
    }, this.animationTimeValue + 100)
  }
}
