import { Controller } from "@hotwired/stimulus"

import flatpickr from "flatpickr";

export default class extends Controller {

  static get values() {
    return { maxDate: String }
  }

  connect() {
    flatpickr(this.element, {
      maxDate: this.maxDateValue
    })
  }
}
